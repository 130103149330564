import React from 'react';
import HomeButton from '../../components/HomeButton/HomeButton';
import ExitButton from '../../components/ExitButton/ExitButton';
import './Header.scss';

const Header = () => {
	return (
		<div className="header">
				<HomeButton />
				<ExitButton />
		</div>
	);
};

export default Header;
