//Packages
import { useState, useEffect } from 'react';
import { Form, Container } from 'react-bootstrap';
import SpotifyWebApi from 'spotify-web-api-node';

//Components
import Header from '../../components/Header/Header';
import TrackControls from '../../components/TrackControls/TrackControls';
import Track from '../../components/Track/Track';

//Styles
import './MusicLibraryPage.scss';


const spotifyApi = new SpotifyWebApi({
	clientId: 'c2173a5b54b34e2a8af3a0f477fe82e0',
});


const MusicLibraryPage = () => {
	let trackLike = false;
	const [search, setSearch] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [playingTrack, setPlayingTrack] = useState([]);
	const [playlist, setPlaylist] = useState([]);
	const accessToken = window.localStorage.accessToken

	//Set The Spotify Access Token
	useEffect(() => {
		if (!accessToken) return;
		console.log(accessToken)
		spotifyApi.setAccessToken(accessToken);
	}, [accessToken]);
	
	//Play Track
	const chooseTrack = (track) => setPlayingTrack(track);

	//Set Search Results to the results of the Search Value If Search or Access Token Changes
	useEffect(() => {
		if (!search) return setSearchResults([]);
		if (!accessToken) return;
		let cancel = false;
		spotifyApi.searchTracks(search).then((res) => {
			if (cancel) return;
			setSearchResults(
				res.body.tracks.items.map((track) => {
					return {
						artist: track.artists[0].name,
						title: track.name,
						uri: track.uri,
						albumUrl: track.album.images[0].url,
					};
				}),
			);
		});
		return () => (cancel = true);
	}, [search, accessToken]);

	//On Page Load (First Component Render, Load User Playlist Liked Songs)
	useEffect(() => {
		if (!accessToken) return;
		spotifyApi
			.getMySavedTracks({
				limit: 10,
			})
			.then(
				(res) => {
					setPlaylist(
						res.body.items.map((playlist) => {
							return {
								artist: playlist.track.artists[0].name,
								title: playlist.track.name,
								uri: playlist.track.uri,
								albumUrl: playlist.track.album.images[0].url,
							};
						}),
					);
				},
				(err) => {
					console.log('Something went wrong!', err);
				},
			);
	}, [accessToken, playlist]);

	//Remove Selected Track From Playlist
	const removeTrackFromPlaylist = (id) => {
		id = id.replace('spotify:track:', '');
		console.log(id);
		spotifyApi
			.removeFromMySavedTracks([id])
			.then((data) => {
				spotifyApi
					.getMySavedTracks({
						limit: 10,
					})
					.then(
						(res) => {
							setPlaylist(
								res.body.items.map((playlist) => {
									return {
										artist: playlist.track.artists[0].name,
										title: playlist.track.name,
										uri: playlist.track.uri,
										albumUrl: playlist.track.album.images[0].url,
									};
								}),
							);
						},
						(err) => {
							console.log('Something went wrong!', err);
						},
					);
			})
			.catch((err) => {
				console.log('Something went wrong', err);
			});
	};


	//RENDER
	return (
		<Container fluid className="background">
			<Header />
			<div className="track-content-container">
				<h2>Library</h2>
				<Form.Control
					type="search"
					placeholder="Search Songs/Artists"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<div className="tracks-wrapper">
					{searchResults.length === 0
						? playlist.map((track) => {
								return (
									<Track
										track={track}
										key={track.uri}
										chooseTrack={chooseTrack}
										trackLike={trackLike}
										removeTrackFromPlaylist={removeTrackFromPlaylist}
									/>
								);
						  })
						: searchResults.map((track) => {
								return (
									<Track
										track={track}
										key={track.uri}
										chooseTrack={chooseTrack}
										removeTrackFromPlaylist={removeTrackFromPlaylist}
									/>
								);
						  })}
				</div>
			</div>
			<TrackControls accessToken={accessToken} trackUri={playingTrack?.uri} />
		</Container>
	);
};

export default MusicLibraryPage;
