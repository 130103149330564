//Packages
import { useEffect, useState } from 'react';

//Pages
import SignInPage from '../SignInPage/SignInPage';
import MusicLibraryPage from '../MusicLibraryPage/MusicLibraryPage';
import SongCustomizePage from '../SongCustomizePage/SongCustomizePage';
import Callback from '../Callback/Callback';

//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/App.scss';

//Routes
import { Routes, Route } from 'react-router-dom';

//Functions
import requestCode from '../../hooks/requestCode'


const App = () => {
	const [code, setCode] = useState(null);
	
	//Grab Return Code from URL
	useEffect(() => {
		setCode(new URLSearchParams(window.location.search).get('code'));
		window.localStorage.setItem('code', code)
	},[setCode, code]);
	

const signIn = () => {
	requestCode()
}


	return (
		<div className="App">
			<Routes>()
				{/* Is there a Spotify Code ? Show the Home Page, else show the Sign In Page  */}

				<Route
					path="/:id/songcustomize"
					element={
						<SongCustomizePage code={code} signIn={signIn} />
					}
				/>
				<Route
					path="/callback"
					element={
						<Callback code={code}/>
					}
				/>
				<Route
					path="/"
					element={
						window.localStorage.accessToken ? (
							<MusicLibraryPage code={code} />
						) : (
							<SignInPage signIn={requestCode} />
						)
					}
				/>
				{/* <Route path='/musiclibrary' element={<MusicLibraryPage code={code} signIn={requestAuthorization}/>}/> */}
			</Routes>
			<div className = 'no-mobile'>
				<h1>Sorry, this app is desktop only...</h1>
			</div>
		</div>
	);
};

export default App;
