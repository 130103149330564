import { useState, useEffect } from 'react';
import SpotifyWebApi from 'spotify-web-api-node';

import SearchButton from '../../components/SearchButton/SearchButton';
import Header from '../../components/Header/Header';
import RangeSlider from '@mui/material/Slider';
import Track from '../../components/Track/Track';
import TrackControls from '../../components/TrackControls/TrackControls';
import './SongCustomizePage.scss';

//Routing
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const spotifyApi = new SpotifyWebApi({
	clientId: 'c2173a5b54b34e2a8af3a0f477fe82e0',
});

const partyPreset = {
	speechinessPreset: [50, 100],
	energyPreset: [70, 100],
	danceabilityPreset: [60, 100],
}
const instrumentalPreset = {
	speechinessPreset: [0, 10],
	energyPreset: [0, 70],
	danceabilityPreset: [0, 40],
}
const rapPreset = {
	speechinessPreset: [70, 100],
	energyPreset: [0, 100],
	danceabilityPreset: [40, 100],
}

const SongCustomizePage = () => {
	//Set Initial Parameters
	const [speechiness, setSpeechiness] = useState([]);
	const [energy, setEnergy] = useState([]);
	const [danceability, setDanceability] = useState([]);
	const [songDuration, setSongDuration] = useState({});
	const [playingTrack, setPlayingTrack] = useState([]);
	const [recommendations, setRecommendations] = useState([]);
	const [url, setUrl] = useState('');
	//Set Current Track
	const [currentTrack, setCurrentTrack] = useState({});

	//Play Track
	const chooseTrack = (track) => {
		setPlayingTrack(track);
	};

	//Grab Authorization Token
	const accessToken = window.localStorage.accessToken;

	//Grab ID from URL
	const selectedSong = useParams();
	const selectedSongID = selectedSong.id.replace('spotify:track:', '');

	//Keep track of changes that occur with the URL for Componenent Render
	useEffect(() => {
		setUrl(selectedSong);
	}, [selectedSong, url]);

	//Set The Spotify Access Token If It Ever Changes
	useEffect(() => {
		if (!accessToken) return;
		spotifyApi.setAccessToken(accessToken);
	}, [accessToken]);

	//Call audio features for track that has the same id
	useEffect(() => {
		spotifyApi.getAudioFeaturesForTrack(selectedSongID).then(
			(data) => {
				console.log(data);
				setDanceability([0, Math.round(data.body.danceability * 100)]);
				setEnergy([0, Math.round(data.body.energy * 100)]);
				setSpeechiness([0, Math.round(data.body.speechiness * 100)]);
				setSongDuration([0, Math.round(data.body.duration_ms / 1000)]);
			},
			(err) => {
				console.log(err);
			},
		);
	}, [selectedSongID]);

	//Get New Track Recommendations Based on Current Track
	const callRecommendations = (id) => {
		spotifyApi
			.getRecommendations({
				seed_tracks: [id],
				min_energy: energy[0] / 100,
				max_energy: energy[1] / 100,
				min_danceability: danceability[0] / 100,
				max_danceability: danceability[1] / 100,
				min_speechiness: speechiness[0] / 100,
				max_speechiness: speechiness[1] / 100,
				min_duration_ms: songDuration[0] * 1000,
				max_duration_ms: songDuration[1] * 1000,
			})
			.then((res) => {
				setRecommendations(
					res.body.tracks.map((track) => {
						return {
							artist: track.artists[0].name,
							title: track.name,
							uri: track.uri,
							albumUrl: track.album.images[0].url,
						};
					}),
				);
			})
			.catch((err) => {
				console.log('something went wrong', err);
			});
	};

	//Retrieve Basic Information About the Track
	useEffect(() => {
		spotifyApi.getTrack(selectedSongID).then(
			(data) => setCurrentTrack(data.body),
			(err) => console.log(err),
		);
	}, [selectedSongID]);

	//Set Slider to Party Preset
	const setPartyPreset = () => {
		setSpeechiness(partyPreset.speechinessPreset)
		setEnergy(partyPreset.energyPreset)
		setDanceability(partyPreset.danceabilityPreset)
	}

//Set Slider to Instrumental Preset
	const setInstrumentalPreset = () => {
		setSpeechiness(instrumentalPreset.speechinessPreset)
		setEnergy(instrumentalPreset.energyPreset)
		setDanceability(instrumentalPreset.danceabilityPreset)
	}

	const setRapPreset = () => {
		setSpeechiness(rapPreset.speechinessPreset)
		setEnergy(rapPreset.energyPreset)
		setDanceability(rapPreset.danceabilityPreset)
	}

	//RENDER
	return (
		<div className="song-container background">
			<Header />
			<div className="track-content">
				<div className="track-info">
					<img
						className="cover-art"
						src={
							Object.keys(currentTrack).length === 0
								? ''
								: currentTrack.album.images[0].url
						}
						alt="Black Party Cover Art"
					/>
					<div className="track-text">
						<h4>
							{Object.keys(currentTrack).length === 0 ? '' : currentTrack.name}
						</h4>
						<p>
							{Object.keys(currentTrack).length === 0
								? ''
								: currentTrack.artists[0].name}
						</p>
					</div>
				</div>
				<div className='presets-wrapper'>
					<button onClick={() => setPartyPreset()}>Party</button>
					<button onClick={() => setInstrumentalPreset()}>Instrumental</button>
					<button onClick={() => setRapPreset()}>Rap</button>
				</div>
				<div className="slider-container">
					<div className="slider positivity-slider">
						<p>Speechiness</p>
						<div className="slider-data">
							<RangeSlider
								getAriaLabel={() => 'Speechiness Range Slider'}
								value={speechiness}
								onChange={(event, newValue) => {
									console.log(newValue)
									setSpeechiness(newValue)
								}}
								valueLabelDisplay="auto"
								sx={{
									color: '#9351BB',
									width: 670,
									'& .MuiSlider-rail': {
										opacity: 1,
										height: 5,
										backgroundColor: '#fff',
									},
								}}
							/>
							<h3>{speechiness[1]}</h3>
						</div>
					</div>
					<div className="slider energy-slider">
						<p>Energy</p>
						<div className="slider-data">
							<RangeSlider
								getAriaLabel={() => 'Energy Range Slider'}
								value={energy}
								onChange={(event, newValue) => setEnergy(newValue)}
								valueLabelDisplay="auto"
								sx={{
									color: '#9351BB',
									width: 670,
									'& .MuiSlider-rail': {
										opacity: 1,
										height: 5,
										backgroundColor: '#fff',
									},
								}}
							/>
							<h3>{energy[1]}</h3>
						</div>
					</div>

					<div className="slider danceability-slider">
						<p>Danceability</p>
						<div className="slider-data">
							<RangeSlider
								getAriaLabel={() => 'Danceability Range Slider'}
								value={danceability}
								onChange={(event, newValue) => setDanceability(newValue)}
								valueLabelDisplay="auto"
								sx={{
									color: '#9351BB',
									width: 670,
									'& .MuiSlider-rail': {
										opacity: 1,
										height: 5,
										backgroundColor: '#fff',
									},
								}}
							/>
							<h3>{danceability[1]}</h3>
						</div>
					</div>
					<div className="slider duration-slider">
						<p>Duration</p>
						<div className="slider-data">
							<RangeSlider
								getAriaLabel={() => 'Duration Range Slider'}
								value={songDuration}
								onChange={(event, newValue) => setSongDuration(newValue)}
								max={400}
								valueLabelDisplay="auto"
								sx={{
									color: '#9351BB',
									width: 670,
									'& .MuiSlider-rail': {
										opacity: 1,
										height: 5,
										backgroundColor: '#fff',
									},
								}}
							/>
							<h3>{songDuration[1]}s</h3>
						</div>
					</div>
				</div>
			</div>
			<div className="flex-center">
				<SearchButton
					currentTrack={currentTrack}
					callRecommendations={callRecommendations}
					recommendations={recommendations}
				/>
			</div>
			<Container style={{ paddingBottom: '175px' }}>
				{recommendations.length === 0 ? (
					<h2 className="no-results">No Results Yet... Try another search!</h2>
				) : (
					recommendations.map((track) => {
						return (
							<Track track={track} key={track.uri} chooseTrack={chooseTrack} />
						);
					})
				)}
			</Container>
			{recommendations.length === 0 ? (
				''
			) : (
				<TrackControls accessToken={accessToken} trackUri={playingTrack?.uri} />
			)}
		</div>
	);
};

export default SongCustomizePage;
