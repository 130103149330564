import React from 'react';
import './Track.scss';

import { ReactComponent as SearchIcon } from '../../assets/icons/search_white_24dp.svg';
import { Link } from 'react-router-dom';

const Track = ({ track, chooseTrack, trackLike, removeTrackFromPlaylist }) => {
	//When this button is pressed, Play the song on the Track Controls!
	const handlePlay = () => {
		chooseTrack(track);
	};

	return (
		<div className="track-container">
			<div className="track-info" onClick={handlePlay}>
				<img src={track.albumUrl} alt="Black Party Cover Art" />
				<div className="track-text">
					<p>{track.title}</p>
					<p>{track.artist}</p>
				</div>
			</div>
			<div className="flex-center track-input-wrapper">
				<button className="search">
					<Link
						exact="true"
						to={{
							pathname: `/${track.uri}/songcustomize`,
							state: track.uri,
						}}>
						<SearchIcon />
					</Link>
				</button>
			</div>
		</div>
	);
};

export default Track;
