//Request Authorization
const requestCode = () => {
		let url = 'https://accounts.spotify.com/authorize';
		url += '?client_id=c2173a5b54b34e2a8af3a0f477fe82e0';
		url += '&response_type=code';
		url += '&redirect_uri=https://www.musicovery.co.uk/callback/';
		url += '&show_dialog=true';
		url += '&scope=';
		url += 'user-read-private%20';
		url += 'user-read-email%20';
		url += 'user-modify-playback-state%20';
		url += 'user-read-playback-position%20';
		url += 'user-library-read%20';
		url += 'user-library-modify%20';
		url += 'streaming%20';
		url += 'user-read-playback-state%20';
		url += 'user-read-recently-played%20';
		url += 'playlist-read-private';
		window.location.href = url;
	};

export default requestCode