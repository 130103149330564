import React from 'react';
import gsap from 'gsap';
import './SearchButton.scss';
import SearchIcon from '../../assets/icons/search_white_24dp.svg';

const SearchButton = ({
	callRecommendations,
	currentTrack,
}) => {
	const ctaExpand = ({ currentTarget }) => {
		gsap.to(currentTarget, {
			transform: 'translateX(20px)',
		});
		gsap.to(currentTarget.children[0], {
			right: '70px',
		});
	};

	const ctaClose = ({ currentTarget }) => {
		gsap.to(currentTarget, {
			transform: 'translateX(-20px)',
		});
		gsap.to(currentTarget.children[0], {
			right: '0px',
		});
	};
	return (
		<button
			onClick={() => {
				callRecommendations(currentTrack.id);
			}}
			className="search-button"
			onMouseEnter={ctaExpand}
			onMouseLeave={ctaClose}>
			<div className="text-wrapper">
				<p>Search</p>
				<img src={SearchIcon} alt="Search Icon" />
			</div>
		</button>
	);
};

export default SearchButton;
