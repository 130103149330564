import './Callback.scss';


//hooks
import useAuth from '../../hooks/useAuth';

const Callback = ({code}) => {
    useAuth(code)
    return <div className = "background">
        <h1>Authenticating...</h1>
    </div>;
};

export default Callback;
