import React from 'react';
import './SignInPage.scss';
import { Container } from 'react-bootstrap';

import SignInButton from '../../components/SignInButton/SignInButton';
// import LinkButton from '../../components/LinkButton/LinkButton';
// import { Link } from 'react-router-dom';

const SignInPage = ({ signIn }) => {
	return (
		<Container fluid className="background">
			<div className="title-container">
				<div className="title">
					<h1>
						<span>Musi</span>
						<span>covery</span>
					</h1>
					<h4>The Ultimate Music Discovery Platform</h4>
					<SignInButton signIn={signIn} text={'Sign In with Spotify'} />
				</div>
			</div>
		</Container>
	);
};

export default SignInPage;
