import React from 'react';
import gsap from 'gsap';
import './SignInButton.scss';

const SignInButton = ({ text, signIn }) => {

	//Expand Animation
	const ctaExpand = ({ currentTarget }) => {
		gsap.to(currentTarget, {
			marginRight: '70px',
		});
		gsap.to(currentTarget.children[0], {
			right: '50px',
		});
	};

	//Close Animation
	const ctaClose = ({ currentTarget }) => {
		gsap.to(currentTarget, {
			marginRight: '100px',
		});
		gsap.to(currentTarget.children[0], {
			right: '0px',
		});
	};

	//Return Component
	return (
		<button
			onClick={signIn}
			className="sign-in-button"
			onMouseEnter={ctaExpand}
			onMouseLeave={ctaClose}>
			<div className="text-wrapper">
				<p>{text}</p>
			</div>
		</button>
	);
};

export default SignInButton;
