import { useEffect, useState } from 'react';
import './TrackControls.scss';
import SpotifyPlayer from 'react-spotify-web-playback';

const TrackControls = ({ accessToken, trackUri }) => {
	const [play, setPlay] = useState(false)

	useEffect(()=> setPlay(true), [trackUri])
	if(!accessToken) return null
	return (
		<div className="track-controls">
			<SpotifyPlayer
				className='track-control'
				token={accessToken}
				magnifySliderOnHover={true}
				styles={{
					bgColor:'#101010',
					sliderColor:'#9351BB',
					activeColor:'#9351BB',
					color:'#fff',
					trackNameColor:'#fff',
					sliderHandleColor:'#fff',
					sliderTrackColor:'#fff',
				}}
				showSaveIcon
				callback={state => {
					if(!state.isPlaying) setPlay(false);
				}}
				play={play}
				uris={trackUri ? [trackUri] : []}
			/>
		</div>
	);
};

export default TrackControls;
