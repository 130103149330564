import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';


ReactDOM.render(
	<BrowserRouter>
					<ScrollToTop/>
			<App />
	</BrowserRouter>,
	document.getElementById('root'),
);
