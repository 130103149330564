import React from 'react';
import gsap from 'gsap';
import './HomeButton.scss';
import HomeIcon from '../../assets/icons/roofing_white_24dp.svg';

//Router
import {Link} from 'react-router-dom';

const HomeButton = () => {
	const ctaExpand = ({ currentTarget }) => {
		gsap.to(currentTarget, {
			transform: 'translateX(20px)',
		});
		gsap.to(currentTarget.children[0], {
			right: '50px',
		});
	};

	const ctaClose = ({ currentTarget }) => {
		gsap.to(currentTarget, {
			transform: 'translateX(-20px)',
		});
		gsap.to(currentTarget.children[0], {
			right: '0',
		});
	};
	return (
		<Link to={{ 
			pathname: `/`,
			code: window.localStorage.code
		 }}>
			<button
				className="home-button"
				onMouseEnter={ctaExpand}
				onMouseLeave={ctaClose}>
				<div className="text-wrapper">
					<p>Home</p>
					<img src={HomeIcon} alt="Home Icon" />
				</div>
			</button>
		</Link>
	);
};

export default HomeButton;
