import React from 'react';
import gsap from 'gsap';
import './ExitButton.scss';
import ExitIcon from '../../assets/icons/arrow.svg';

const ActionButton = () => {

	//Log Out Function
	const logout = () =>{
		window.localStorage.removeItem('accessToken');
		window.localStorage.removeItem('code');
		window.location = window.location.origin;
	}

	// Slider Animation
	const ctaExpand = ({ currentTarget }) => {
		gsap.to(currentTarget, {
			transform: 'translateX(20px)',
		});
		gsap.to(currentTarget.children[0], {
			right: '50px'
		});
	};

	const ctaClose = ({ currentTarget }) => {
		gsap.to(currentTarget, {
			transform: 'translateX(-20px)',
		});
		gsap.to(currentTarget.children[0], {
			right: '0px'
		});
	};
	return (
		<button
			onClick={()=>{logout()}}
			className='exit-button'
			onMouseEnter={ctaExpand}
			onMouseLeave={ctaClose}>
			<div className="text-wrapper">
				<p>Exit</p>
				<img src={ExitIcon} alt="Exit Icon" />
			</div>
		</button>
	);
};

export default ActionButton;
