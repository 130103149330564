import { useState, useEffect } from 'react';
import axios from 'axios';

const useAuth = (code) => {
	const [accessToken, setAccessToken] = useState();
	const [refreshToken, setRefreshToken] = useState();
	const [expiresIn, setExpiresIn] = useState();

	useEffect(() => {
		if (code === null) return
		axios
			.post('https://hidden-shelf-10650.herokuapp.com/login', {
				code,
			})
			.then((res) => {
				setAccessToken(res.data.accessToken)
                setRefreshToken(res.data.refreshToken)
                setExpiresIn(res.data.expiresIn)
				window.history.pushState({}, null, '/');
				window.localStorage.setItem('accessToken', res.data.accessToken);
				document.location.href = '/';
			}).catch((err) => {
				console.log(code)
				console.log(err);
				console.log(window.localStorage.accessToken);
				window.location = '/';
			});
		}, [code]);

    useEffect(()=>{
        if(!refreshToken || !expiresIn) return
        const interval = setInterval(()=>{
            axios
			.post('https://hidden-shelf-10650.herokuapp.com/refresh', {
				refreshToken,
			})
			.then((res) => {
				setAccessToken(res.data.accessToken)
                setExpiresIn(res.data.expiresIn)
			})
			.catch((err) => {
				console.log(err);
				window.location = '/';
			});
        }, (expiresIn - 60) * 1000)

        return () => clearInterval(interval)
    }, [refreshToken, expiresIn ])

    return accessToken
};

export default useAuth;
